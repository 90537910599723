import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";
import Popup from "./Popup";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action";
import Loader from "react-js-loader";

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

const AutoAgreement = () => {

    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()


    const [autoFormDataState, setAutoFormDataState] = useState([])
    const [fieldNamesState, setFieldNamesState] = useState([])
    const [otherDataState, setOtherDataState] = useState({})




    // const [checkBoxString, setCheckBoxString] = useState([]);
    // const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState("");
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [promt, setPromt] = useState(false)

    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("New File");
    const [tagStore, setTagStore] = useState([]);
    const [handleStateOfTags, setHandleStateOfTags] = useState(false);
    const [joke, setJoke] = useState({
        setup: "",
        punchline: ""
    })

    const [checkBoxString, setCheckBoxString] = useState([]);




    useEffect(() => {
        getDataFromUrlParameter()
    }, []);


    const getDataFromUrlParameter = () => {
        if (location.state) {
            const { fileName, fieldNames, otherData } = location.state;
            setAutoFormDataState(fileName);
            setOtherDataState(otherData);
            setFieldNamesState(fieldNames);
            localStorage.setItem("tabData", JSON.stringify(location.state));
        } else {
            const tempTabData = JSON.parse(localStorage.getItem("tabData"));
            if (tempTabData) {
                setAutoFormDataState(tempTabData.fileName);
                setOtherDataState(tempTabData.otherData);
                setFieldNamesState(tempTabData.fieldNames);
            }
        }

        // getDataFromStorage();
    };

    useEffect(() => {
        if (UserService.isLoggedIn()) getPlanDetails()
        // if (UserService.isLoggedIn()) getDataFromLibrary()
        resetButtonView()
    }, []);

    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         otherDataState && saveDatatoStorage()
    //     };
    //     document.addEventListener('input', handleKeyPress);
    //     document.addEventListener('click', handleKeyPress);

    //     return () => {
    //         document.removeEventListener('input', handleKeyPress);
    //         document.removeEventListener('click', handleKeyPress);
    //     };
    // }, [tagStore, otherDataState]);

    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem(otherDataState.storageName))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)

            fieldNamesState.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                if (field) {
                    field.value = masterStorageObject[fieldName] || '';
                }
            });
        }
    }
    const saveDatatoStorage = () => {
        const toggleButton = document.getElementById("toggle-button");
        const toggleLable = toggleButton.querySelector(
            ".toggle-button-label"
        ).innerText;
        let mode = "";
        if (toggleLable === "Pro") mode = "Pro";
        else mode = "Basic";

        const fileName = document.getElementById("input-file-name").value

        const masterStorageObject = {}

        fieldNamesState && fieldNamesState.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            const value = field ? field.value : "";
            masterStorageObject[fieldName] = value;
        });
        masterStorageObject.mode = mode;
        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;
        otherDataState && localStorage.setItem(otherDataState.storageName, JSON.stringify(masterStorageObject))
    }


    const getDataFromLibrary = () => {
        // if (location.state?.data) {
        //     const outputJSONfromLibrary = location.state?.data
        //     // setOutputDetailsDataFromLibrary(location.state?.data)
        //     setRenameString(outputJSONfromLibrary.file_name)
        //     if (outputJSONfromLibrary.file_tags && outputJSONfromLibrary.file_tags != null && outputJSONfromLibrary.file_tags != "null") setTagStore(outputJSONfromLibrary.file_tags.split(";"))

        //     // document.getElementById("other").value = outputJSONfromLibrary.api_input.tipsytom_input.agreement_type
        //     document.getElementById("title").value = outputJSONfromLibrary.api_input.tipsytom_input.title
        //     document.getElementById("reference").value = outputJSONfromLibrary.api_input.tipsytom_input.ref
        //     document.getElementById("date").value = outputJSONfromLibrary.api_input.tipsytom_input.date
        //     document.getElementById("client").value = outputJSONfromLibrary.api_input.tipsytom_input.client
        //     document.getElementById("recipient").value = outputJSONfromLibrary.api_input.tipsytom_input.recipient
        //     document.getElementById("subject").value = outputJSONfromLibrary.api_input.tipsytom_input.subject
        //     document.getElementById("facts").value = outputJSONfromLibrary.api_input.tipsytom_input.facts
        //     document.getElementById("violation").value = outputJSONfromLibrary.api_input.tipsytom_input.violation
        //     document.getElementById("legalBasis").value = outputJSONfromLibrary.api_input.tipsytom_input.legalbasis
        //     document.getElementById("remedy").value = outputJSONfromLibrary.api_input.tipsytom_input.remedy
        //     document.getElementById("timeLimits").value = outputJSONfromLibrary.api_input.tipsytom_input.timelimits
        //     document.getElementById("consequences").value = outputJSONfromLibrary.api_input.tipsytom_input.consequences
        //     document.getElementById("jurisdiction").value = outputJSONfromLibrary.api_input.tipsytom_input.jurisdiction
        //     document.getElementById("list").value = outputJSONfromLibrary.api_input.tipsytom_input.lists
        //     
        // }
    }
    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end")
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end")
            })
        }
    }
    const preventFromCopyPaste = () => {
        const edit = document.getElementById("edit");
        if (edit) {
            edit.addEventListener("copy", preventDefault);
            edit.addEventListener("cut", preventDefault);

            function preventDefault(e) {
                e.preventDefault();
            }
        }
    };

    const getPlanDetails = async (value) => {
        if (UserService.isLoggedIn()) {
            try {
                if (!value === "noRefresh") setLoaderCredit(true); // wanna keep the form datas so using this
                const headers = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "access_token": UserService.getToken()
                };
                const getResponse = await axios.post(
                    `${REACT_APP_CREATE_AGREEMENT_API}/get_user_status?keycloak_user_id=${UserService.getUserId()}`,
                    null,
                    { headers: headers }
                );
                if (getResponse.status === 200) {
                    dispatch(getUserDetails(getResponse.data));
                    const download = getResponse.data.user_credit_summary.num_remaining_downloads;
                    // dispatch(
                    //     getNotification({
                    //         message: `You have ${download} ${download === 1 || download === 0 ? "credit" : "credits"
                    //             }`,
                    //         type: "info",
                    //     })
                    // );
                }
            } catch (err) {
                // console.log("Error", err);
                if (err.response && err.response.status === 400) {
                    dispatch(getUserDetails(err.response.data.detail));
                    // dispatch(
                    //   getNotification({
                    //     message:
                    //       "You have run out of credits!! Please subscribe and get more credits.",
                    //     type: "info",
                    //   })
                    // );
                } else if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` });
                    }, 3000);
                } else {
                    dispatch(
                        getNotification({
                            message: "Network issue. Please refresh the page or try again after sometime..",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderCredit(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` });
            }, 3000);
        }
    };

    const getScrrenTOLoader = () => {
        const element = document.getElementById("my-agreement-spinner");
        const offset = -200; // Adjust this value to set the desired offset

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth" // Optionally, you can add smooth scrolling animation
        });
    }
    const getJoke = async () => {
        try {

            const response = await axios.get("https://official-joke-api.appspot.com/random_joke")
            // console.log(response.data);
            setJoke({
                setup: response.data.setup,
                punchline: response.data.punchline
            })
        }
        catch (err) {
            console.log("error", err);
        }
    }

    const sendData = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        // const title = document.getElementById("title").value;
        // const reference = document.getElementById("reference").value;
        // const date = document.getElementById("date").value;
        // const client = document.getElementById("client").value;
        // const recipient = document.getElementById("recipient").value;
        // const subject = document.getElementById("subject").value;
        // const facts = document.getElementById("facts").value;
        // const violation = document.getElementById("violation").value;
        // const legalBasis = document.getElementById("legalBasis").value;
        // const remedy = document.getElementById("remedy").value;
        // const timeLimits = document.getElementById("timeLimits").value;
        // const consequences = document.getElementById("consequences").value;
        // const jurisdiction = document.getElementById("jurisdiction").value;
        // const lists = document.getElementById("list").value


        const toggleButton = document.getElementById("toggle-button");
        const toggleLable = toggleButton.querySelector(
            ".toggle-button-label"
        ).innerText;
        let mode = "";
        if (toggleLable === "Pro") mode = "Pro";
        else mode = "Basic";
        if (value) mode = "Pro"

        let interval;
        getJoke()


        setHandleStateOfTags(true)
        interval = setInterval(getJoke, 10000);
        if (UserService.isLoggedIn()) {
            try {
                setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("agreement_type", "Legal Notice");
                sendData.append("file_name", renameString);
                sendData.append("file_tags", tagStore.join(",").replaceAll(",", ";").replace(/;$/, ''));
                sendData.append("api_type_alias", mode);



                // sendData.append("title", title);
                // sendData.append("ref", reference);
                // sendData.append("date", date);
                // sendData.append("client", client);
                // sendData.append("recipient", recipient);
                // sendData.append("subject", subject);
                // sendData.append("facts", facts);
                // sendData.append("violation", violation);
                // sendData.append("legalbasis", legalBasis);
                // sendData.append("remedy", remedy);
                // sendData.append("timelimits", timeLimits);
                // sendData.append("consequences", consequences);
                // sendData.append("jurisdiction", jurisdiction);
                // sendData.append("lists", lists);

                fieldNamesState.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    const value = field ? field.value : "";
                    sendData.append(fieldName, value);
                });


                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/${otherDataState.authAPI}`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setHandleSubmit(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const text = getResponse.data.agreement_text;
                    setCreatAgreement(text);
                    getPlanDetails("noRefresh");
                    // console.log("Success");
                }
            } catch (err) {
                console.log(err);
                clearInterval(interval);
                setHandleSubmit(false);
                setLoaderAgreement(false)
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "Sorry, our network is down with too many requests. Please try in sometime!",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderAgreement(false);
            //  else {
            //   dispatch(
            //     getNotification({
            //       message:
            //         "You have run out of credits!! Please subscribe and get more credits.",
            //       type: "info",
            //     })
            //   );
            //   setTimeout(() => navigate("/subscription"), 3000);
            // }
        } else if (otherDataState.unAuthAPI) {
            const unauthData = JSON.parse(localStorage.getItem("UnAuthkey"))
            try {
                setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", unauthData.id);
                sendData.append("ip_address", unauthData.ip.ip_address);
                sendData.append("user_agent", unauthData.ip.user_agent);
                sendData.append("agreement_type", "Legal Notice");
                sendData.append("file_name", renameString);
                sendData.append("file_tags", tagStore.join(",").replaceAll(",", ";").replace(/;$/, ''));
                sendData.append("api_type_alias", "Basic");


                fieldNamesState.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    const value = field ? field.value : "";
                    sendData.append(fieldName, value);
                });

                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/${otherDataState.authAPI}`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setHandleSubmit(true);
                const time = new Date()
                if (unauthData.value) {
                    const getResponse = await axios(config);
                    if (getResponse.status === 200) {
                        const text = getResponse.data.agreement_text;
                        setCreatAgreement(text);
                        unauthData.track.legalNoticApi.push(time)
                        unauthData.value -= 1
                        localStorage.setItem('UnAuthkey', JSON.stringify(unauthData));
                        // dispatch(
                        //   getNotification({
                        //     message: `You have ${unauthData.value} ${unauthData.value === 1 || unauthData.value === 0 ? "credit" : "credits"
                        //       } left today`,
                        //     type: "info",
                        //   })
                        // );
                        // getPlanDetails("noRefresh");
                        // console.log("Success");
                    }
                } else {
                    setHandleSubmit(false);
                    dispatch(
                        getNotification({
                            message: "You have exceeded the daily limit of 15 credits. Please try again tomorrow or register for free account and subscribe to our free plan.",
                            type: "default",
                        })
                    );
                    setTimeout(() => window.open("/sign-up", '_blank'), 3500)
                }
            } catch (err) {
                setHandleSubmit(false);
                clearInterval(interval);
                setLoaderAgreement(false)
                // console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "Sorry, our network is down with too many requests. Please try in sometime!",
                        type: "default",
                    })
                );
            }
            setLoaderAgreement(false);

        }
        else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` });
            }, 3000);
        }
        setTimeout(() => preventFromCopyPaste(), 100);
    };

    const download = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("input_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/export_as_docx`,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year}-${day}-${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${otherDataState.downloadFileName}_${UserService.getUsername()}_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                }
            } catch (err) {
                setLoaderDownload(false);
                console.log("Error", err);
                if (err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/lease-agreement` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "Network issue. Please click on the download button again.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` });
            }, 3000);
        }
        setTimeout(() => preventFromCopyPaste(), 10);
    };

    // const checkBoxStringMaker = (event, name) => {
    //   if (event.target.checked === true) {
    //     checkBoxString.push(name);
    //     setCheckBoxString([...checkBoxString]);
    //   } else {
    //     checkBoxString.pop(name);
    //     setCheckBoxString([...checkBoxString]);
    //   }
    // };

    const resetForm = () => {
        fieldNamesState.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = '';
            }
        });
        setRenameString("New File")
        let temp = []
        setTagStore(temp)
        setHandleStateOfTags(false)
    };

    const toggleSwitch = () => {
        const toggleButton = document.getElementById("toggle-button");
        toggleButton.classList.toggle("active");
        const toggleLable = toggleButton.querySelector(".toggle-button-label");

        if (toggleButton.classList.contains("active")) {
            toggleLable.classList.remove("mx-2.5");
            toggleLable.classList.add("add-margin");
        } else {
            toggleLable.classList.remove("add-margin");
            toggleLable.classList.add("mx-2.5");
        }
        toggleLable.textContent = toggleButton.classList.contains("active")
            ? "Pro"
            : "Use Pro";
    };

    // const getCurrentMonthYear = () => {
    //   const date = new Date();
    //   const month = date.toLocaleString("default", { month: "long" });
    //   const year = date.getFullYear();
    //   return `${month} ${year}`;
    // };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input")
        let tempArr = [...tagStore]
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        }
        else {
            tempArr.push(tag.value)
        }
        setTagStore(tempArr);
        tag.value = ""
    }

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore]
        if (value) tempArr = tempArr.filter(e => e !== value)
        setTagStore(tempArr);
    }

    const handleConfirm = () => {
        sendData();
        setPromt(false)
    };
    const handleProConfirm = () => {
        toggleSwitch()
        sendData(true)
        setPromt(false)
    }
    const handleCancel = () => {
        setPromt(false)
    };

    const checkBoxStringMaker = (event, name) => {
        if (event.target.checked === true) {
            checkBoxString.push(name);
            setCheckBoxString([...checkBoxString]);
        } else {
            checkBoxString.pop(name);
            setCheckBoxString([...checkBoxString]);
        }
    };



    return (
        <div id="contract" className="container-fluid contract">
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div>
                        <div className="md:grid md:grid-cols-4 md:gap-6">
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                {/* <form> */}
                                <div className="shadow sm:overflow-hidden sm:rounded-md p-1">
                                    <div className="menu-bar mt-1">
                                        <button className="btn btn-success ml-3">
                                            <input
                                                id="input-file-name"
                                                className={rename ? "" : "d-none"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") setRename(false);
                                                }}
                                                onChange={(e) => {
                                                    setRenameString(e.target.value);
                                                }}
                                                defaultValue={renameString}
                                                type="text"
                                                style={{
                                                    width: "150px",
                                                    height: "30px",
                                                    color: "black",
                                                }}
                                            />
                                            <span
                                                onClick={() => {
                                                    if (rename) setRename(false);
                                                    else setRename(true);
                                                }}
                                                id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 20 ? "right" : "bottom"} className={rename ? "d-none" : ""}>
                                                {renameString.length > 20 ? `${renameString.slice(0, 20)}...` : renameString}
                                                <span className="ml-2">|</span>
                                            </span>
                                            <i
                                                onClick={() => {
                                                    if (rename) setRename(false);
                                                    else setRename(true);
                                                }}
                                                className={
                                                    rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                }
                                            ></i>
                                            {/* <i className="fas fa-times"></i> */}
                                        </button>
                                        <button
                                            id="toggle-button"
                                            className="btn toggle-button mx-2 float-end mt-1"
                                            onClick={() => {
                                                if (UserService.isLoggedIn()) {
                                                    //for this check : plan is active and credits are zero then user can use atleast basic
                                                    if (Object.keys(state.planDetails.user_subscription_summary).length === 0) {
                                                        dispatch(
                                                            getNotification({
                                                                message: "Your don't have any active plan!! Please subscribe and get more credits.",
                                                                type: "info",
                                                            })
                                                        );
                                                        setTimeout(() => window.open("/app/subscription"), 3500);
                                                    }
                                                    else if (Object.keys(state.planDetails.user_subscription_summary).length && Object.entries(state.planDetails.user_subscription_summary)[0][1].status === "active" && state.planDetails.user_credit_summary.num_remaining_downloads === 0) {
                                                        dispatch(
                                                            getNotification({
                                                                message: "You have run out of credits!! Please add top up plan and get more credits to use PRO mode.",
                                                                type: "info",
                                                            })
                                                        );
                                                        setTimeout(() => window.open("/app/subscription"), 3500);
                                                    }
                                                    else if (Object.keys(state.planDetails.user_subscription_summary).length && Object.entries(state.planDetails.user_subscription_summary)[0][1].status !== "active") {
                                                        dispatch(
                                                            getNotification({
                                                                message: "Your plan got expired !! Please subscribe and get more credits.",
                                                                type: "info",
                                                            })
                                                        );
                                                        setTimeout(() => window.open("/app/subscription"), 3500);
                                                    }
                                                    else toggleSwitch()
                                                }
                                                else {
                                                    dispatch(
                                                        getNotification({
                                                            message:
                                                                "Sign-up now and prepare superior quality drafts using the Owl mode!",
                                                            type: "info",
                                                        })
                                                    )
                                                    setTimeout(() => window.open("/sign-up", '_blank'), 3500)
                                                }
                                            }}
                                        >
                                            <span
                                                // spiketip-title="Use PRO" spiketip-pos="bottom" 
                                                className="toggle-button-label fw-bolder mx-2.5">
                                                Use Pro
                                            </span>
                                        </button>
                                        <button
                                            spiketip-title="Reset Form" spiketip-pos="bottom"
                                            onClick={() => {
                                                resetForm();
                                            }}
                                            className="btn btn-dark float-end"
                                        >
                                            <i className="fas fa-redo"></i>
                                        </button>
                                        <button
                                            spiketip-title={handleSubmit ? `${tagStore.length} tag${tagStore.length >= 2 ? "s" : ""} added.` : "Add Tag"} spiketip-pos="bottom"
                                            onClick={() => getTagButtonFromArray()}
                                            id="add-tag"
                                            className="btn btn-dark round-btn mx-2 mt-0.5 float-end"
                                        >
                                            {handleSubmit ?
                                                <span className="-mx-2 fw-bolder">
                                                    {tagStore.length}
                                                </span> :
                                                <i className="fas fa-plus plus-position"></i>
                                            }
                                        </button>
                                        <input
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    getTagButtonFromArray(e.target.value)
                                                }
                                            }}
                                            placeholder="Add tag here"
                                            className={handleStateOfTags ? "d-none" : "px-1 mt-1.5 rounded btn custom-input float-end"}
                                            style={{
                                                height: "30px",
                                                // fontSize: handleSubmit ? "14px" : "",
                                                width: "110px",
                                                border: "2px solid",
                                                textAlign: "left",
                                            }}
                                            type="text"
                                        />
                                        <span className="scrollable-span mx-2 mt-2 float-end pb-1">
                                            {tagStore.length > 0 &&
                                                !handleSubmit &&
                                                tagStore.map((ele, ind) => (
                                                    <button
                                                        key={ind}
                                                        className="btn btn-dark ml-2 p-0 px-1 position-relative"
                                                        onMouseEnter={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.remove("d-none")
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.add("d-none")
                                                        }}
                                                    >
                                                        {ele}
                                                        <i
                                                            // style={{ display: "none" }}
                                                            onClick={() => { removeTagFromArray(ele) }}
                                                            className="fas fa-window-close ml-1 d-none"></i>
                                                    </button>
                                                ))}
                                        </span>
                                    </div>
                                    <hr className="mt-2 mb-0" />
                                    <div className="space-y-6 bg-white sm:p-4">
                                        <div className="grid grid-cols-3 gap-1">
                                            {autoFormDataState.map((e, i) => {
                                                if (e.element === "input") {
                                                    return (
                                                        <div key={i} className="col-span-6 sm:col-span-3">
                                                            <label htmlFor={e.id} className="block text-sm font-medium text-gray-700">
                                                                {e.name} <span className={e.required ? "text-danger" : "d-none"}>*</span>
                                                            </label>
                                                            <input
                                                                type={e.type ? e.type : "text"}
                                                                placeholder={e.placeholder}
                                                                name={e.id}
                                                                id={e.id}
                                                                autoComplete="given-name"
                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    );
                                                } else if (e.element === "textarea") {
                                                    return (
                                                        <div key={i} className="col-span-6 sm:col-span-3">
                                                            <label htmlFor={e.id} className="block text-sm font-medium text-gray-700">
                                                                {e.name}
                                                            </label>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    id={e.id}
                                                                    name={e.id}
                                                                    rows={3}
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                    placeholder={e.placeholder}
                                                                    defaultValue={""}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                } else if (e.element === "checkbox-with-heading") {
                                                    return (
                                                        <div key={i} className="col-span-6 sm:col-span-3 mt-1">
                                                            <fieldset>
                                                                <legend className="sr-only">By Email</legend>
                                                                <div
                                                                    className="text-sm font-semibold text-gray-900"
                                                                    aria-hidden="true"
                                                                >
                                                                    {e.name}
                                                                </div>
                                                                {
                                                                    e.checkboxes.map((ele, ind) => (
                                                                        <div key={i * 100} className="mt-1">
                                                                            <div className="flex items-start">
                                                                                <div className="flex h-5 items-center">
                                                                                    <input
                                                                                        id={String(i) + String(ind)}
                                                                                        onChange={(event) => {
                                                                                            checkBoxStringMaker(event, ele);
                                                                                        }}
                                                                                        type="checkbox"
                                                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 check"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm">
                                                                                    <label
                                                                                        htmlFor={String(i) + String(ind)}
                                                                                        className="font-medium text-gray-700 cursor-pointer checkbox-class-other"
                                                                                    >
                                                                                        {ele}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </fieldset>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return null; // Return null or handle other element types here
                                                }
                                            })}


                                            {/* <div key={i} className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="list"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Document Lists: <spna className="text-default">*</spna>
                            </label>
                            <div className="mt-1">
                              <input
                                type="file"
                                multiple
                                placeholder="List of any supporting documents"
                                name={list}
                                id={list}
                                autoComplete="given-name"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div> */}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            {promt && <Popup
                                                message1="As an unlogged in user, Tipsy Tom will show you a draft which can't be downloaded. Sign-up free to generate and download documents for free!"
                                                message2={UserService.isLoggedIn() ? otherDataState.AuthPromtData : otherDataState.UnauthPromtData}
                                                mode={document.getElementById("toggle-button").querySelector(".toggle-button-label").innerText === "Pro" ? true : false}
                                                onConfirm={handleConfirm}
                                                onProConfirm={handleProConfirm}
                                                onCancel={handleCancel} />}
                                            <button
                                                spiketip-title="Submit Form" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-success mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => { setPromt(true) }}
                                            >
                                                Submit
                                                <i className="fas fa-upload mx-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title={
                                            document.getElementById("toggle-button") && document.getElementById("toggle-button").classList.contains("active") ?
                                                "It can take up to 90 seconds for TipsyTom to generate a Pro legal notice for you."
                                                :
                                                "Please wait for a few seconds while TipsyTom generates a legal notice for you."
                                        }
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "font-italic text-center mt-5" : "d-none"}> The strongest of all warriors are these two - time and patience</h5>
                                    <br />
                                    <h4 className="font-italic text-center mt-5"> {joke.setup}</h4>
                                    <h5 className="font-italic text-center mt-2"> {joke.punchline}</h5>
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar mt-1">
                                            <span className="scrollable-span-right pb-1">
                                                {tagStore.length > 0 &&
                                                    handleSubmit && handleStateOfTags &&
                                                    tagStore.map((ele, ind) => (
                                                        <button key={ind} className="btn btn-dark ml-1 p-0 px-1">
                                                            {ele}
                                                        </button>
                                                    ))}
                                            </span>
                                            <button
                                                spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                className={UserService.isLoggedIn() ? "btn btn-primary mx-4" : "btn btn-secondary mx-4"}
                                                onClick={() => {
                                                    if (UserService.isLoggedIn()) {
                                                        setContentState(!contentState);
                                                        setTimeout(() => preventFromCopyPaste(), 10);
                                                        if (contentState) {
                                                            document.getElementById(
                                                                "edit"
                                                            ).contentEditable = true;
                                                            document
                                                                .getElementById("download")
                                                                .setAttribute("disabled", true);
                                                        } else {
                                                            document.getElementById(
                                                                "edit"
                                                            ).contentEditable = false;
                                                            document
                                                                .getElementById("download")
                                                                .removeAttribute("disabled");
                                                        }
                                                    }
                                                    else UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` })
                                                }}
                                            >
                                                <i
                                                    id="icon"
                                                    className={
                                                        contentState ? "fas fa-edit" : "fas fa-save"
                                                    }
                                                ></i>
                                            </button>
                                            <button
                                                spiketip-title="Download Agreement" spiketip-pos="bottom"
                                                id="download"
                                                title="Download Agreement"
                                                className={UserService.isLoggedIn() ? "btn btn-primary" : "btn btn-secondary"}
                                                onClick={() => {
                                                    if (UserService.isLoggedIn()) download();
                                                    else UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` })
                                                    // else dispatch(
                                                    //   getNotification({
                                                    //     message:
                                                    //       "Plesae sign up or log in to download the document.",
                                                    //     type: "info",
                                                    //   })
                                                    // );
                                                }}
                                            >
                                                <i className="fas fa-download"></i>
                                            </button>
                                        </div>
                                        <hr className="mt-2" />

                                        <div className="px-3">
                                            <div
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    height: otherDataState.formHeight,
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >
                                                {createAgreement}
                                                <br />
                                            </div>
                                            <button
                                                spiketip-title="Download Agreement" spiketip-pos="top"
                                                className={`btn btn-${UserService.isLoggedIn() ? "success" : "secondary"} mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                                                onClick={() => {
                                                    if (UserService.isLoggedIn()) download();
                                                    else UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/agreement` })
                                                    // else dispatch(
                                                    //   getNotification({
                                                    //     message:
                                                    //       "Plesae sign up or log in to download the document.",
                                                    //     type: "info",
                                                    //   })
                                                    // );
                                                }}
                                            >
                                                Download Agreement{" "}
                                                <i className="fas fa-download mx-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default AutoAgreement